import Vue from "vue";
import Vuex from "vuex";
import api from "@/plugins/axios.js";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    offers: null,
    news: null,
    newsByID: null,
    slides: null,
    settings: null,
    visitors: 0,
    message: {
      dismissCountDown: 0,
      text: "",
      state: "",
    },
  },
  getters: {
    offers: (state) => state.offers,
    news: (state) => state.news,
    newsByID: (state) => state.newsByID,
    slides: (state) => state.slides,
    message: (state) => state.message,
    visitors: (state) => state.visitors,
    settings: (state) => state.settings,
  },
  mutations: {
    SET_OFFERS(state, data) {
      state.offers = data;
    },
    SET_NEWS(state, data) {
      state.news = data;
    },
    SET_NEWS_BY_ID(state, data) {
      state.newsByID = data;
    },
    SET_SLIDES(state, data) {
      state.slides = data;
    },
    SET_MESSAGE(state, data) {
      state.message = data;
    },
    SET_VISITORS(state, data) {
      state.visitors = data;
    },
    SET_SETTINGS(state, data) {
      state.settings = data;
    },
  },
  actions: {
    async getOffers({ commit }, payload) {
      const res = await api.get(
        `/offers?take=${payload.take ? payload.take : "10"}&page=${
          payload.page ? payload.page : "1"
        }&find[status]=${
          payload.filters.status ? payload.filters.status : "1"
        }&sort=-created_at`
      );
      commit("SET_OFFERS", res.data);
    },
    async getNews({ commit }, payload) {
      const res = await api.get(
        `/news?take=${payload.take ? payload.take : "10"}&page=${
          payload.page ? payload.page : "1"
        }&find[is_special]=${
          payload.filters.isSpecial ? payload.filters.isSpecial : ""
        }&find[status]=${
          payload.filters.status ? payload.filters.status : "1"
        }&sort=-created_at`
      );
      commit("SET_NEWS", res.data);
    },
    async getNewsByID({ commit }, id) {
      let res = await api.get(`/news/${id}?include=media`);
      commit("SET_NEWS_BY_ID", res.data);
    },
    async getSlides({ commit }, payload) {
      const res = await api.get(
        `/sliders?take=${payload.take ? payload.take : "10"}&page=${
          payload.page ? payload.page : "1"
        }&find[status]=${
          payload.filters.status ? payload.filters.status : "1"
        }&sort=-created_at`
      );
      commit("SET_SLIDES", res.data);
    },
    async getVisitors({ commit }) {
      const res = await api.get("/visitors");
      commit("SET_VISITORS", res.data);
    },
    async getSettings({ commit }) {
      const res = await api.get("/about");
      commit("SET_SETTINGS", res.data);
    },
    async sendMessage({ commit }, payload) {
      try {
        await api.post("/contacts", payload);
        commit("SET_MESSAGE", {
          text: "تم ارسال الرسالة بنجاح, سوف نرد عليك في اقرب وقت",
          state: "success",
          dismissCountDown: 10,
        });
      } catch (e) {
        if (e.response.status === 429) {
          commit("SET_MESSAGE", {
            text: `يمكنك ارسال رسالة اخرى بعد مرور `,
            state: "warning",
            dismissCountDown: 60,
          });
        } else {
          commit("SET_MESSAGE", {
            text: "حدث خطأ في ارسال الرسالة يرجى المحاولة مجددا في وقت لاحق",
            state: "danger",
            dismissCountDown: 10,
          });
        }
      }
    },
  },
  modules: {},
});
