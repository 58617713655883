<template>
  <div class="section-header">
    <h2>{{ title }}</h2>
    <p>{{ subtitle }}</p>
  </div>
</template>

<script>
export default {
  props: ["title", "subtitle"],
};
</script>
