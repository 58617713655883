<template>
  <div class="news-card">
    <img :src="image" alt="" v-if="image" />
    <div class="image-placeholder" v-else>
      <i class="fa-regular fa-image"></i>
    </div>
    <div class="card-content">
      <h3>{{ title }}</h3>
      <p v-html="subtitle"></p>
      <b-button
        v-if="id"
        variant="primary"
        @click="$router.push({ name: 'newsByID', params: { id: id } })"
        >اقرأ المزيد</b-button
      >
    </div>
  </div>
</template>

<script>
export default {
  props: ["id", "image", "title", "subtitle"],
  created() {
    console.log(this.id);
  },
};
</script>
