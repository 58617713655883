<template>
  <div class="navbar-component">
    <b-navbar toggleable="sm" type="light" variant="light" :sticky="true">
      <b-container>
        <b-navbar-brand href="/" v-if="settings && settings.logo">
          <img :src="settings.logo" width="80" alt="" />
        </b-navbar-brand>

        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

        <b-collapse id="nav-collapse" is-nav>
          <ul class="nav-links">
            <li><router-link :to="{ name: 'home' }">الرئيسية</router-link></li>
            <li><router-link :to="{ name: 'offers' }">العروض</router-link></li>
            <li><router-link :to="{ name: 'news' }">الاخبار</router-link></li>
            <li>
              <a href="/#contact-us">تواصل معنا</a>
            </li>
            <li><router-link :to="{ name: 'about' }">من نحن</router-link></li>
          </ul>
        </b-collapse>
      </b-container>
    </b-navbar>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters(["settings"]),
  },
};
</script>
