<template>
  <div class="home">
    <!-- header -->
    <header
      class="header"
      :style="{ backgroundImage: 'url(' + settings.cover + ')' }"
    >
      <div class="overlay"></div>
      <b-container class="h-100">
        <b-row class="h-100">
          <b-col sm="12" md="12" lg="6" class="h-lg-100">
            <div class="header-content">
              <h1 class="title">{{ activeTitle }}</h1>
              <!-- <b-button variant="primary">معرفة المزيد</b-button> -->
              <router-link :to="{ name: 'offers' }" class="btn btn-primary"
                >شاهد المزيد</router-link
              >
            </div>
          </b-col>
          <b-col sm="12" md="12" lg="6" class="h-lg-100">
            <div class="slider-container" v-if="slides && slides.data.length">
              <div class="keen-slider" ref="slider">
                <div
                  class="keen-slider__slide lazy__slide"
                  v-for="(src, idx) in slides.data"
                  :key="idx"
                >
                  <img :src="loaded[idx] ? src.cover_orginal : ''" />
                </div>
              </div>
              <div v-if="slider" class="dots">
                <button
                  v-for="(_slide, idx) in dotHelper"
                  @click="slider.moveToIdx(idx)"
                  :class="{ dot: true, active: current === idx }"
                  :key="idx"
                ></button>
              </div>
            </div>
          </b-col>
        </b-row>
        <!-- <b-row>
          <b-col sm="12">
            <div class="visitors">
              <p class="num">
                <span>عدد الزوار</span>
                <span class="mx-2"></span>
                <vue-count-up
                  class="fw-bold"
                  :startVal="0"
                  :endVal="visitorCount"
                  :duration="2"
                  :options="countUpOptions"
                />
                <span class="mx-2"></span>
                <span>زائر</span>
              </p>
            </div>
          </b-col>
        </b-row> -->
      </b-container>
    </header>
    <!-- header -->
    <!-- main -->
    <main>
      <!-- offers section -->
      <section class="section bg-light">
        <b-container>
          <section-header
            title="عروضنا المميزة"
            subtitle="تمتع بأفضل العروض المميزة لك ولعائلتك"
          />
          <b-row v-if="offers && offers.data.length">
            <b-col sm="12" md="4" v-for="offer in offers.data" :key="offer.id">
              <offers-card
                :image="offer.cover_orginal"
                :title="offer.title"
                :subtitle="offer.description"
              />
            </b-col>
          </b-row>
          <b-row v-else>
            <b-col sm="12">
              <div class="no-data-message">
                نعتذر لعدم وجود اي عروض في الوقت الحالي
              </div>
            </b-col>
          </b-row>
          <b-row v-if="offers && offers.data.length">
            <b-col sm="12">
              <div class="section-footer">
                <!-- <b-button variant="primary">شاهد المزيد</b-button> -->
                <router-link :to="{ name: 'offers' }" class="btn btn-primary"
                  >شاهد المزيد</router-link
                >
              </div>
            </b-col>
          </b-row>
        </b-container>
      </section>
      <!-- end of offers section -->
      <!-- news section -->
      <section class="section news-section">
        <b-container>
          <section-header
            title="اتطلع على اخر الاخبار"
            subtitle="كن على اتطلاع بكل جديد "
          />
          <b-row v-if="news && news.data.length">
            <b-col sm="12" md="6" v-for="item in news.data" :key="item.id">
              <news-card
                :id="item.id"
                :image="item.cover_orginal"
                :title="item.title"
                :subtitle="item.subtitle"
              />
            </b-col>
          </b-row>
          <b-row v-else>
            <b-col sm="12">
              <div class="no-data-message">
                نعتذر لعدم وجود اي خبر في الوقت الحالي
              </div>
            </b-col>
          </b-row>
          <b-row v-if="news && news.data.length">
            <b-col sm="12">
              <div class="section-footer">
                <router-link :to="{ name: 'news' }" class="btn btn-primary"
                  >شاهد المزيد</router-link
                >
              </div>
            </b-col>
          </b-row>
        </b-container>
      </section>
      <!-- end of news section -->
      <!-- contact us section -->
      <section class="contact-section" id="contact-us">
        <b-container fluid="xl">
          <b-row>
            <b-col sm="12" md="6">
              <div class="contact-content">
                <div>
                  <h2>لا تتردد بالتواصل معنا فان رايك يهمنا</h2>
                  <p>
                    نحن مستعدون للاجابة على جميع استفساراتكم ونتطلع لسماع
                    طلباتكم, لذلك لاتتردد بالتواصل معنا.
                  </p>
                  <p v-if="settings && settings.socials.length">
                    اطلع على جديدنا عبر مواقع التواصل الاجتماعي
                  </p>
                  <ul
                    class="socials"
                    v-if="settings && settings.socials.length"
                  >
                    <li
                      class="social-link"
                      v-for="link in settings.socials"
                      :key="link.url"
                    >
                      <a :href="link.url" target="_blank">
                        <i :class="link.icon"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </b-col>
            <b-col sm="12" md="6">
              <b-form @submit.prevent="onSubmit">
                <b-row>
                  <b-col sm="12" md="6">
                    <b-form-group
                      class="required"
                      label="الاسم الثلاثي:"
                      label-for="name"
                    >
                      <b-form-input
                        id="name"
                        v-model="form.name"
                        placeholder="الاسم الثلاثي"
                        required
                        oninvalid="this.setCustomValidity('يرجى ادخال اسمك الثلاثي ليتسنى لنا معرفة مع من نتكلم')"
                        oninput="setCustomValidity('')"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col sm="12" md="6">
                    <b-form-group
                      class="required"
                      label="البريد الالكتروني:"
                      label-for="email"
                    >
                      <b-form-input
                        id="email"
                        v-model="form.email"
                        type="email"
                        placeholder="البريد الالكتروني"
                        required
                        oninvalid="this.setCustomValidity('يرجى ادخال بريدك الالكتروني لكي نستطيع الرد عليك في اقرب وقت')"
                        oninput="setCustomValidity('')"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col sm="12" md="6">
                    <b-form-group label="رقم الهاتف:" label-for="phone">
                      <b-form-input
                        id="phone"
                        v-model="form.phone"
                        type="number"
                        placeholder="رقم الهاتف"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col sm="12" md="6">
                    <b-form-group
                      class="required"
                      label="العنوان:"
                      label-for="subject"
                    >
                      <b-form-input
                        id="subject"
                        v-model="form.title"
                        placeholder="العنوان"
                        required
                        oninvalid="this.setCustomValidity('يرجى ادخال عنوان الرسالة لكي نفهم احتياجاتك بوضوح')"
                        oninput="setCustomValidity('')"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                </b-row>

                <b-form-group label="الرسالة:" label-for="message">
                  <b-form-textarea
                    id="message"
                    v-model="form.message"
                    placeholder="الرسالة"
                    rows="8"
                    max-rows="6"
                  ></b-form-textarea>
                </b-form-group>

                <b-alert
                  :show="message.dismissCountDown"
                  :variant="message.state"
                  @dismissed="message.dismissCountDown = 0"
                  @dismiss-count-down="countDownChanged"
                >
                  {{ message.text }} <span>&nbsp;</span
                  ><span v-if="message.state === 'warning'"
                    >{{ dismissCountDown }} ثانية</span
                  >
                </b-alert>

                <b-button type="submit" variant="primary">ارسال</b-button>
              </b-form>
            </b-col>
          </b-row>
        </b-container>
      </section>
      <!-- end of contact us section -->
    </main>
    <!-- end of main -->
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import "keen-slider/keen-slider.min.css";
import KeenSlider from "keen-slider";
// import VueCountUp from "vue-countup-v2";

import SectionHeader from "@/components/SectionHeader.vue";
import OffersCard from "@/components/OffersCard.vue";
import NewsCard from "@/components/NewsCard.vue";

export default {
  name: "HomeView",
  components: {
    SectionHeader,
    OffersCard,
    NewsCard,
    // VueCountUp,
  },
  metaInfo: {
    title: "مدينة العاب الزوراء الترفيهي",
    titleTemplate: "%s - الرئيسية",
    meta: [
      {
        name: "description",
        content:
          "اكبر مدينة العاب في العراق تضم اجمل واحدث الالعاب الترفيهية والمناسبة لجميع الاعمار ومطاعم ومقاهي وحد",
      },
    ],
  },
  data() {
    return {
      loaded: [true],
      slider: null,
      current: 0,
      visitorsNum: 0,
      activeTitle: "",
      form: {},
      dismissCountDown: 0,
      visitorCount: 0,
      countUpOptions: {
        useEasing: true, // Enable easing for a smoother animation
        useGrouping: true, // Enable grouping separator (e.g., 1,000)
        separator: ",", // Set the grouping separator
        decimal: ".", // Set the decimal separator
        prefix: "+", // Add a prefix (e.g., '$')
        suffix: "", // Add a suffix (e.g., ' visitors')
      },
    };
  },
  computed: {
    ...mapGetters([
      "slides",
      "offers",
      "news",
      "message",
      "visitors",
      "settings",
    ]),
    dotHelper() {
      return this.slider
        ? [...Array(this.slider.track.details.slides.length).keys()]
        : [];
    },
  },
  methods: {
    ...mapActions([
      "getSlides",
      "getOffers",
      "getNews",
      "sendMessage",
      "getVisitors",
    ]),
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown;
    },
    async onSubmit() {
      await this.sendMessage(this.form);
      if (this.message.state === "success") {
        this.form = {};
      }
    },
    updateVisitorCount(newCount) {
      this.visitorCount = newCount;
    },
  },
  beforeDestroy() {
    if (this.slider) this.slider.destroy();
  },
  async created() {
    await this.getSlides({ filters: {} }).then(() => {
      this.activeTitle = this.slides.data[this.current].title;
      this.slider = new KeenSlider(
        this.$refs.slider,
        {
          animationEnded: (s) => {
            const idx = s.track.details.rel;
            this.loaded[idx] = true;
            this.$forceUpdate();
          },
          loop: true,
          initial: this.current,
          rtl: true,
          slides: {
            perView: 1,
          },
          slideChanged: (s) => {
            this.current = s.track.details.rel;
            this.activeTitle = this.slides.data[this.current].title;
          },
        },
        [
          (slider) => {
            let timeout;
            let mouseOver = false;
            function clearNextTimeout() {
              clearTimeout(timeout);
            }
            function nextTimeout() {
              clearTimeout(timeout);
              if (mouseOver) return;
              timeout = setTimeout(() => {
                slider.next();
              }, 3000);
            }
            slider.on("created", () => {
              slider.container.addEventListener("mouseover", () => {
                mouseOver = true;
                clearNextTimeout();
              });
              slider.container.addEventListener("mouseout", () => {
                mouseOver = false;
                nextTimeout();
              });
              nextTimeout();
            });
            slider.on("dragStarted", clearNextTimeout);
            slider.on("animationEnded", nextTimeout);
            slider.on("updated", nextTimeout);
          },
        ]
      );
    });
    await this.getVisitors().then(() => {
      this.updateVisitorCount(this.visitors);
    });
    await this.getOffers({
      take: 6,
      page: 1,
      filters: {},
    });
    await this.getNews({
      take: 6,
      page: 1,
      filters: { isSpecial: "1" },
    });
  },
};
</script>

<style lang="scss">
.lazy__slide {
  height: 500 !important;
  width: 800px;
  background-image: url("https://keen-slider.io/images/loader.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-size: 50px;
  min-height: auto;
}

/* @media (min-width: 768px) {
  .lazy__slide {
    height: 300px;
  }
} */

.lazy__slide img {
  height: 400px;
  width: 100%;
  border-radius: 2rem;
  object-fit: cover;
  @media only screen and (max-width: 990px) {
    width: 705px;
  }
  @media only screen and (max-width: 600px) {
    width: 100%;
    height: 300px;
  }
}
</style>
