<template>
  <div id="app">
    <!-- message modal -->
    <b-modal
      id="messageModal"
      title="ارسال رسالة عبر الواتساب"
      hide-footer
      centered
    >
      <b-form-group label="الرسالة:" label-for="message">
        <b-form-textarea
          id="message"
          v-model="form.message"
          placeholder="اكتب رسالتك هنا"
          rows="8"
          max-rows="6"
        ></b-form-textarea>
      </b-form-group>
      <b-row>
        <b-col sm="12">
          <b-button type="submit" variant="primary" @click="sendMessage"
            >ارسال</b-button
          >
        </b-col>
      </b-row>
    </b-modal>
    <!-- end of message modal -->
    <nav-bar />
    <router-view />
    <footer-component />
    <button class="whatsapp-btn" @click="$bvModal.show('messageModal')">
      <i class="fa-brands fa-whatsapp"></i>
    </button>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import NavBar from "@/components/NavBar.vue";
import FooterComponent from "@/components/FooterComponent.vue";
export default {
  components: {
    NavBar,
    FooterComponent,
  },
  data() {
    return {
      form: {
        phone: +9647866554422,
        message: "",
      },
    };
  },
  methods: {
    ...mapActions(["getSettings"]),
    sendMessage() {
      window.open(
        `https://wa.me/${this.form.phone}?text=${this.form.message}`,
        "_blank"
      );
    },
  },
  async created() {
    await this.getSettings();
  },
};
</script>
