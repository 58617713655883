<template>
  <div class="offers-card">
    <img :src="image" alt="" v-if="image" />
    <div class="image-placeholder" v-else>
      <i class="fa-regular fa-image"></i>
    </div>
    <div class="card-content">
      <h3>{{ title }}</h3>
      <p v-html="subtitle"></p>
    </div>
  </div>
</template>

<script>
export default {
  props: ["image", "title", "subtitle"],
};
</script>
