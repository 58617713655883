import axios from "axios";

// export const baseUrl = "http://154.62.109.41:8090/api";
export const baseUrl = "https://dashboard.zawraa.com/api";

axios.interceptors.request.use((config) => {
  config.baseURL = baseUrl;

  config.headers = {
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };
  return config;
});

export default axios;
